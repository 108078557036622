import REQ from "@/app/REQ";
import {mapState} from "vuex";
import {assignBypath, classifyByProp, genMap, upWFoundById} from '@/utils/xyhUtils'
import {DOC_ROLE, DOC_TYPE, LANGUAGE_MODEL, REMARK_STATE, DOC_STATE} from "@/consts";
import selRefDocDlg from '@/dialg/selRefDocDlg.vue';
import selRefDlg from '@/doc/selRefDlg.vue'
import docEditState from "@/doc/docEditState";
import {wordViewUrl} from "@/doc/consts"

export default {
    props: ['id'],
    components:{selRefDocDlg,selRefDlg},

    data() {
        return {
            annexes: [],
            docs:[],
            lang: LANGUAGE_MODEL.Cn,
            doc: {},
            remarks: [],
            kMax: 200,
            commenting:false,
            fdPath:'',
            discuss:'',
            repliedId:null,
            translating:false,
            contentTranslate:[],
            prjts:[],
            addDoc:false,
            refIdxToSet:null,
            refPropN:'',
            terms:[],
            abbrs:[],
            laws:[],
            pmStaffs:[],
            authorStaffs:[],
            auditorStaffs:[],
            amIAuditor:false,
            Strs:[],
            relation:false,
            refNamePath:'',
            roles:[],
        }
    },

    provide:function () {
        return {
            updateDisplayContent:this.updateDisplayContent,
        }
    },

    created() {
        docEditState.clean()
        this.getDoc()
        this.getMyDocPrjts()
        this.getTerms()
        this.getAbbrs()
        this.getLaws()
    },

    computed: {
        lanMode: _ => docEditState.lanMode,   //语言

        ...mapState({
            staffId: ({curStaff}) => curStaff?.id,   //我在公司内部的id
        }),
        CnOn(){
            return docEditState.lanMode !== LANGUAGE_MODEL.En   //中文相关模式
        },
        EnOn(){
            return docEditState.lanMode !== LANGUAGE_MODEL.Cn   //英文相关模式
        },
        ...mapState({
            orgId: ({ curOrg }) => curOrg?.id,   //公司id
        }),

        displayContent: _ => docEditState.displayContent,
    },

    methods:{

        getDocCrRolesOfPrjt(){
            let {prjtId} = this.doc
            this.req2Data(REQ.docCrRolesOfPrjt, {prjtId}, null)
        },

        //排序
        sorted(arrN, propN){
            this.content[arrN].sort((parm1, parm2) => parm1[propN].toLowerCase().localeCompare(parm2[propN].toLowerCase()))
        },

        //获取文档列表
        getDocPrjt() {
            this.req2Data(REQ.docPrjtInLib, this.doc.prjtId, {docs:'docs'},({docs})=> {
                this.docId = ''
                docs.sort((doc1, doc2) => doc1.num.toLowerCase().localeCompare(doc2.num.toLowerCase()))
                for(let i = 0; i < docs.length; i++){
                    if(docs[i].id == this.myId){
                        this.docs.splice(i,1)
                        break
                    }
                }
                this.annexes = docs.filter(it=>it.type == DOC_TYPE.annex)
            })
        },

        //获取项目文档列表
        getDocs(){
            this.req2Data(REQ.docPrjtInLib, this.doc.prjtId, {docs: 'docs'},()=> {
                this.extractStrOfObj(this.content)
            })
        },

        //更改对应文档引用格式
        updateDisplayContent(path, str){
            let filterResult = [...str.matchAll(/(《)((.(?!\n)(?!《)(?!》))*)([(（])((.(?!\n)(?!《)(?!》))*)([)）][\s]{0,5})(》)/g)]
            let res = Object.values(genMap(filterResult, 0));
            for (let val of res) {
                let doc = this.docs.find(item => item.num.toLowerCase() == val[5].toLowerCase())
                if (doc) {
                    let docN = val[2].trim() != doc.name.trim() ? (val[1] + '<span style="color:red">' + val[2] + '</span>' + val[4] + val[5] + val[7] + val[8]) : val[0]
                    let url = window.location.origin + '/#/main/doc/' + doc.id
                    str = str.replaceAll(val[0], '<a style="color: #0D8FBF" href="' + url + '" target="_blank">' + docN + '</a>')
                } else {
                    str = str.replaceAll(val[0], '<span style="color:red">' + val[0] + '</span>')
                }
            }
            this.$set(docEditState.displayContent, path, str)
        },

        //提取正文内容（对象）
        extractStrOfObj(content, path){
            for(let i in content){
                if(i != 'refFlows' && i != 'terms' && i != 'abbrs' && i != 'annexes'){
                    let fdpath = (path || 'content') + '.' + i
                    if(Array.isArray(content[i])){
                        this.extractStrOfArr(content[i],fdpath)
                    }else {
                        if (content[i] && typeof content[i] === 'string') {
                            this.updateDisplayContent(fdpath, content[i])
                            this.Strs.push(content[i])
                        } else if (content[i]) {
                            this.extractStrOfObj(content[i],fdpath)
                        }
                    }
                }
            }
        },

        //数组
        extractStrOfArr(arr, fdpath){
            arr.forEach(it=>{
                let itPath = fdpath + '.' + it.k
                if(Array.isArray(it)){
                    this.extractStrOfArr(it)
                }else if(it && typeof it === 'string'){
                    this.Strs.push(it)
                }else if(it){
                    this.extractStrOfObj(it, itPath)
                }
            })
        },

        //预览参考文件
        previewRefFlow(id){
            window.open(wordViewUrl +
                encodeURIComponent(XyhConf.HglSHost + '/' + REQ.previewDoc + '?id=' + id + '&orgId=' + this.orgId + '&t=' + new Date().getTime()))
        },

        //预览附件
        previewAnnexe(id){
            window.open(wordViewUrl + encodeURIComponent(this.annexes.find(it=>it.id == id).file))
        },

        //设置语言
        onSetLanMode(mode) { docEditState.lanMode = mode},

        //获取我的文档项目列表
        getMyDocPrjts(){
            this.req2Data(REQ.myDocPrjts, null, {prjts:'prjts'},_=> this.addDoc = true)
        },

        //获取术语
        getTerms(){
            this.req2Data(REQ.terms,{}, 'terms', )
        },

        //获取缩略语
        getAbbrs(){
            this.req2Data(REQ.abbrs,{}, 'abbrs', )
        },

        //获取法规
        getLaws(){
            this.req2Data(REQ.laws,{}, 'laws', )
        },

        //检测k是否存在，同时为保持兼容性，此时k值由idx生成（文档代码初期k由idx生成）
        checkToInitK(obj) {
            for (let i in obj) {
                if (typeof obj[i] !== 'string') {
                    if (Array.isArray(obj[i])) {
                        obj[i].forEach((it, idx) => {
                            if (typeof it === 'string') {
                                obj[i][idx] = {k: idx, v: it}
                            } else if (it) {
                                if (it.k) return true
                                it.k = idx
                                if (this.checkToInitK(it)) return true
                            }
                        })
                    } else {
                        if (this.checkToInitK(obj[i])) return true
                    }
                }
            }
        },

        setValue(data) {
            this.content[data.fd] = data.value
        },

        //生成k
        genNewK() {
            return ++this.kMax
        },
    }
}