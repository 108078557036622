import docEditState from "@/doc/docEditState";

export default {
    name: "currencyInput",
    props:['val','path', 'w',],
    inject: ['updateDisplayContent'],

    data() {
        return{
            strN:'',
            border:{'width':this.w + 'px'},
            pre:{'width':this.w-10 + 'px'},
        }
    },

    computed: {
        displayContent: _ => docEditState.displayContent,
    },

    created() {
        this.strN = this.val
    },

    watch:{
        val(){
            this.strN = this.val
        },
    },

    methods:{

    }
}