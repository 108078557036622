import currencyInput from "./currencyInput.vue";
import docEditState from "@/doc/docEditState";
import {LANGUAGE_MODEL} from "@/consts";

export default {
    name: "docItem",
    props:['valCn','valEn','path','w',],
    components:{currencyInput},

    data() {
        return{
        }
    },

    computed:{
        CnOn(){
            return docEditState.lanMode !== LANGUAGE_MODEL.En
        },
        EnOn(){
            return docEditState.lanMode !== LANGUAGE_MODEL.Cn
        }
    },

    methods:{

    }
}